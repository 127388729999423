import GLOBALS from './globals';
import { io } from "socket.io-client";

import Blublu from './blublu';

export default class Socket {
    socket = null;
    listeners = {};
    connected = false;
    connectedCallback = null;
    constructor(connectedCallback) {
        this.connectedCallback = connectedCallback;
        this.connect();
    }

    async connect() {
        let token = await Blublu.getAuthCredentials();
        if (! token) {
            this.connected = false;
            setTimeout(()=> {
                this.connect();
            }, 5000);
            return;
        }




        this.socket = io(Blublu.getSocketApiHost(), {
            auth: {
                token : token,
            },
            transports: ['websocket']
        });

        global.socket = this.socket;

        this.socket.on("connect_error", (error)=> {
            // from here we should push login screen?
            //alert(error);
            console.log("_______________________________")
            console.log(error);
        })

        this.socket.on("connect_timeout", ()=> {
        })
        this.socket.on("reconnect", ()=> {
        })
        this.socket.on("reconnect_attempt", ()=> {
        })
        this.socket.on("reconnecting", ()=> {
        })
        this.socket.on("reconnect_error", (error)=> {
        })
        this.socket.on("reconnect_failed", ()=> {
        })




        this.socket.on("connect", ()=> {
            console.log("CONNECTED");
            this.connected = true;
            if (this.connectedCallback) {
                this.connectedCallback(this.socket);
            }
        })
        this.socket.on("reconnect", ()=> {
        })
        this.socket.on("do-auth", ()=> {
        })
    }

    on(topic, callback) {
        if (! this.listeners[topic]) {
            this.listeners[topic] = [];
            this.socket.on(topic, (message)=> {
                this.broadcast(topic, message);
            })
        }
        this.listeners[topic].push(callback);
    }

    emit(topic, data) {
        this.socket.emit(topic, data)
    }

    broadcast(topic, message) {
        if (!this.listeners[topic]) {
            console.log("Topic is missing");
            return;
        }
        let outListeners = [];
        for (let listener of this.listeners[topic]) {
            try {
                listener(message);
                outListeners.push(listener);
            } catch (e) {
                console.log("Unable to call listener");
                console.log(e);
            }
        }
        // only push back working listeners
        this.listeners[topic] = outListeners;
    }
};

