import React, { useState, useEffect } from 'react'

import {
    CBadge,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CForm,
    CDataTable,
    CRow,
    CPagination, CLabel, CContainer, CCardGroup, CInputGroup, CInputGroupPrepend, CInputGroupText, CInput, CButton
} from '@coreui/react'

import Blublu from "../../lib/blublu";
import usersData from './UsersData'
import Select from "react-select";
import {Redirect} from "react-router-dom";
import CIcon from "@coreui/icons-react";




export default class AcceptInvite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAccount: false,
            inviteChecked: false,
            errorMessage: "",
            providerName: "",
            resetSent: false,
            accountId: "",
            providerId: ""
        }
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        console.log(this.props);
        let params = this.props.match.params;
        let result = await Blublu.get(`public/invite-accepted/${params.accountId}/${params.providerId}`);
        this.setState({inviteChecked: true, email: result.email, providerName: result.provider_name, accountId: params.accountId, providerId: params.providerId})
        if (result.requires_register !== true) {
            this.setState({showAccount: true})
        }
    }

    updatePassword(ev) {
        this.setState({password: ev.target.value});
    }

    doSetPassword() {
        let reg = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/im);
        if (! reg.test(this.state.password)) {
            return this.showError("Password is not strong enough");
        }

    }


    showError(message) {
        this.setState({errorMessage: message})
    }

    async startReset() {
        let result = await Blublu.get(`public/request-password-reset/${this.state.accountId}`)
        if (result.success) {
            this.setState({resetSent: true})
        }
        console.log(result);
    }

// need to add another api call to "set up" user with a password, not really a register..
    render() {

        if (this.state.showAccount) {
            return <Redirect to="/account" push={false} />
        }

        if (this.state.resetSent) {
            return (
                <div className="c-app c-default-layout flex-row align-items-center">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol md="8">
                                <CCardGroup>
                                    <CCard className="p-4">
                                        <CCardBody>
                                            <CForm>
                                                <p>An email has been sent to {this.state.email}, please follow the instructions included in the email</p>
                                                <p>You can close this window</p>
                                            </CForm>
                                        </CCardBody>
                                    </CCard>
                                </CCardGroup>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            )
        } else if (this.state.inviteChecked) {
            return (
                <div className="c-app c-default-layout flex-row align-items-center">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol md="8">
                                <CCardGroup>
                                    <CCard className="p-4">
                                        <CCardBody>
                                            <CForm>
                                                <h2>You have been invited by {this.state.providerName}</h2>
                                                <p style={{padding:10}}>Sent to {this.state.email}</p>
                                                <CButton  color="primary" className="px-4" onClick={()=>this.startReset()}>Confirm account</CButton>
                                            </CForm>
                                        </CCardBody>
                                    </CCard>
                                </CCardGroup>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            );
        }  else {
            return (
                <div className="c-app c-default-layout flex-row align-items-center">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol md="8">
                                <CCardGroup>
                                    <CCard className="p-4">
                                        <CCardBody>
                                            <CForm>
                                                <h2>Validating invite...</h2>
                                            </CForm>
                                        </CCardBody>
                                    </CCard>
                                </CCardGroup>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            )
        }

    }

    handleClick(ev) {
        console.log(ev)
    }

}




