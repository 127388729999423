import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow, CSidebarBrand
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import Blublu from "../../../lib/blublu";

export default class ResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            email: "",
            requestResetSuccess: false
        }
    }

    componentDidMount() {
    }



    async requestReset() {
        let result = await Blublu.get("public/request-password-reset/" + this.state.email);
        if (result.success) {
            alert("A password reset email has been sent to " + this.state.email);
            this.setState({requestResetSuccess: true})

        } else {
            alert("Unable to start the password reset process. " + result.message || "")
        }
    }

    updateEmail(ev) {
        this.setState({email: ev.target.value});
    }


    render() {
        if (this.state.requestResetSuccess) {
            return <Redirect to="/login" push={false} />
        }
        return (
            <div className="c-app c-default-layout flex-row align-items-center">
                <CContainer>
                    <CRow className="justify-content-center">
                        <CCol md="8">
                            <CCardGroup>
                                <CCard className="p-4">
                                    <CCardBody>
                                        <CForm>
                                            <h1>Reset Password</h1>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                        <CIcon name="cil-user" style={{width:20, height: 20}}/>
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <CInput type="text" placeholder="Email address" autoComplete="email" value={this.state.email} onChange={(value)=> this.updateEmail(value)} />
                                            </CInputGroup>
                                            <CButton color={"success"} onClick={()=>this.requestReset()}>Request Reset</CButton>
                                        </CForm>
                                    </CCardBody>
                                </CCard>
                                <CCard className="text-black bg-secondary py-5 d-md-down-none" style={{ width: '44%' }}>
                                    <CCardBody className="text-center">
                                        <div>
                                            <img
                                                src="./images/blublu.png"
                                                className="img-fluid" style={{maxHeight: 25}}/>
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        );
    }
}

