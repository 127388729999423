import React, { Component } from 'react';
import { Router, HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import store from 'store';
import history from "./lib/history";
import blublu from "./lib/blublu";
import Login from './views/pages/login/Login';
import AcceptInvite from './views/users/AcceptInvite';
import ResetPassword from './views/pages/login/ResetPassword';
//import { AcceptInvite } from './views/pages/invite/accept-invite';
import Blublu from "./lib/blublu";

import Messaging from './lib/messaging';
import Socket from './lib/socket';

import { initializeApp } from 'firebase/app';



const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)



// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages


//const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
if (! global) {
    global = {};
}
global.store = store;


new Socket((socket)=> {
    new Messaging(socket);
});
setTimeout(()=> {
    //let messaging = new Messaging();


}, 1000)


class App extends Component {
    constructor() {
        super();
        this.init();
    }

    async init() {
        await Blublu.initGlobals();
        await Blublu.reloadAccount();

        let firebaseConfig = {
            apiKey: "AIzaSyB9Z7IkeD5-nw9SY_3KyHQfHrnVrVzFgNs",
            authDomain: "blubber-91b6a.firebaseapp.com",
            projectId: "blubber-91b6a",
            storageBucket: "blubber-91b6a.appspot.com",
            messagingSenderId: "468231969894",
            appId: "1:468231969894:web:251cac358223fdad2d21a7",
            measurementId: "G-X0T1YHS8JE"

        };

        initializeApp(firebaseConfig);
        //export const auth = firebase.auth();
        //export const firestore = firebase.firestore();

    }



    render() {
        return (
            <HashRouter history={history}>
                <React.Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                        <Route exact path="/reset-password" name="Reset password" render={props => <ResetPassword {...props}/>} />
                        <Route exact path="/accept-invite/:accountId/:providerId" name="Accept Invite" render={props => <AcceptInvite {...props}/>} />
                        <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                        <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                    </Switch>
                </React.Suspense>
            </HashRouter>
        );
    }
}

export default App;
//<Route exact path="/accept-invite/:providerId/:email" name="Accept Invite" render={props => <AcceptInvite {...props}/>} />
