//import GLOBALS from './globals';
//import { io } from "socket.io-client";
import uuid from 'react-native-uuid';
//import Blublu from './blublu';

export default class Messaging {
    socket = null;
    static MESSAGE_TYPE = {
        GENERAL: 10,
        ORDER: 20,
        TRANSACTION: 30,
        DELIVERY: 40,
        RESERVATION: 50
    }
    constructor(socket) {
        this.socket = socket;
        console.log("INIT MESSAGING")
        global.messaging = this;
    }

    async getMessages(topicId, lastTS, topicTypeId) {
        return new Promise((resolve, reject)=> {
            let id = uuid.v4();
            this.socket.once("messaging-" + id, (data)=> {
                resolve(data);
            });
            this.socket.emit("messaging", {
                action: "get_messages",
                last_ts: lastTS,
                topic_id: topicId,
                topic_type_id: topicTypeId || Messaging.MESSAGE_TYPE.GENERAL,
                id: id
            });

        });

    }

    sendMessage(topicId, to, providerId, message, topic_type_id, appId) {
        this.socket.emit("messaging", {
            action: "new_message",
            topic_id: topicId,
            topic_type_id: topic_type_id || 10,
            provider_id: providerId,
            message: message,
            to: to,
            app_id: appId
        });
    }

    onNewMessage(callback, topicId) {
        this.socket.on("messaging_new", (data)=> {
            console.log("GETTING:")
            console.log(data);
            if (topicId && data.topic_id == topicId) {
                callback(data)
            } else if (!topicId) {
                // ke?
                callback(data)

            }
        })
    }


}
