import GLOBALS from './globals';

export default class Blublu {

    PAYMENT_STATUS_PENDING = 1;
    PAYMENT_STATUS_PAID = 2;
    PAYMENT_STATUS_USER_CANCEL = 3;
    PAYMENT_STATUS_FAILED = 4;
    PAYMENT_STATUS_PARTIAL = 5;

    ORDER_STATUS = {
        ON_HOLD: {CODE: 1, TEXT: "On Hold, awaiting payment"},
        PROCESSING: {CODE: 2, TEXT: "Processing"},
        COMPLETED: {CODE: 3, TEXT: "Completed"},
        FAILED: {CODE: 4, TEXT: "Failed"},
        CANCELLED: {CODE: 5, TEXT: "Cancelled"},
        REFUNDED: {CODE: 6, TEXT: "REFUNDED"},
        QUOTE_REQUESTED: {CODE: 7, TEXT: "Quote requested"},
        WAITING_DELIVERY: {CODE: 200, TEXT: "Waiting for delivery"},
        OUT_DELIVERY: {CODE: 210, TEXT: "Out for delivery"},
        DELIVERY_FAILED: {CODE: 220, TEXT: "Delivery failed"}
    }


    constructor() {
    }


    static setStore(key, value) {
        global.store.set(key, value);
    }

    static getStore(key) {
        try {
            return global.store.get(key)
        } catch (e) {
            return null;
        }

    }

    static async reloadAccount() {
        let currentProvider = await Blublu.getStore("currentProvider");
        if (! currentProvider) {
            return;
        }
        let result = await Blublu.get("account");
        if (! result.success) {
            return;
        }
        if (result.providers && result.providers.length > 0) {
            let updatedProvider = null;
            for (let provider of result.providers) {
                if (provider.provider_id == currentProvider.provider_id) {
                    updatedProvider = provider;
                }
            }
            if (updatedProvider) {
                await Blublu.setStore("currentProvider", updatedProvider);
            }
        }

    }

    static async initGlobals() {
        try {
            global.isBMAdmin = await Blublu.getStore("globalAccess").indexOf("bm-all") > -1;
            let currentProvider = await Blublu.getStore("currentProvider");
            let inviteAccepted = false;
            if (currentProvider?.invite_accepted == 1) {
                inviteAccepted = true;
                Blublu.startStatusPoll(currentProvider.provider_id)
                global.providerAccess = currentProvider.access || [];
                global.providerModules = currentProvider.modules || [];
            } else {
                global.providerAccess = [];
                global.providerModules = [];
            }
            global.inviteAccepted = inviteAccepted;
        } catch (e) {
            console.log(e);
            //pass
        }
    }

    static hasModule(name) {
        if (! global.providerModules) {
            return false;
        }
        return global.providerModules.indexOf(name) > -1 ? true : false;
    }

    static hasAccess(types) {
        if (global.isBMAdmin) {
            return true;
        }
        if (global.providerAccess.indexOf("all") > -1) {
            return true;
        }
        let numRemainingTypes = types.length;
        try {
            for (let type of types) {
               if (global.providerAccess.indexOf(type) > -1) {
                   numRemainingTypes-= 1;
               }
            }
        } catch (e) {
            return false;
        }
        if (numRemainingTypes <= 0) {
            return true;
        } else {
            return false;
        }
    }

    static startStatusPoll(providerId) {
        if (! providerId) {
            console.log("Not polling for status, no provider Id")
            return;
        }
        if (! global.statusPoll) {
            global.statusPoll = setInterval(async ()=> {
                let result = await Blublu.get("provider/summary/" + providerId);
                if (result.success) {
                    await Blublu.setStore("orderCount", result.orders);
                    await Blublu.setStore("transactionCount", result.transactions);
                }
            }, 5000)
        }
    }


    static async setCurrentProvider(provider) {
        console.log(provider);

        await this.setStore("currentProviderId", provider.provider_id);
        await this.setStore("currentProvider", provider);
        await Blublu.initGlobals();

    }

    static getCurrentProvider() {
        return this.getStore("currentProvider");
    }

    static getCurrentProviderId() {
        return this.getStore("currentProviderId");
    }

    static socket() {
        return global.socket;
    }

    static getMessaging() {
        return global.messaging;
    }



    static async getAuthCredentials() {
        try {
            return await this.getStore("token");
        } catch (e) {
            return "";
        }

    }


    static async setAuthCredentials(credentials) {
        //await global.storage.save({ key: "authCredentials", data: credentials });
        return "123"; // credentials;
    }


    static async get(path, args, headers) {
        return await Blublu.call(path, args, "GET", headers);
    }

    static async post(path, args, headers) {
        return await Blublu.call(path, args, "POST", headers);
    }

    static async put(path, args, headers) {
        return await Blublu.call(path, args, "PUT", headers);
    }

    static async delete(path, args, headers) {
        return await Blublu.call(path, args, "DELETE", headers);
    }

    static getSocketApiHost() {
      return process.env.REACT_APP_API_SOCKET_URL;
    }

    static getApiHost() {
        return process.env.REACT_APP_API_URL;
    }

    static isEmpty(object) {
        return Object.keys(object).length == 0;
    }

    static async getSecureImage(url) {
        let authCredentials = await Blublu.getAuthCredentials();
        if (! authCredentials) {
            return null;
        }
        let headers = {'Authorization': 'Bearer ' + authCredentials};
        try {
            const response = await fetch(
                url,
                {
                    method: 'GET',
                    headers: headers,
                    timeout: 10000,
                }
            );
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            return objectUrl;
        } catch (e) {
            return null;
        }
    }

    static async call(path, args, method, customHeaders) {
        let authCredentials = await Blublu.getAuthCredentials();
        if (args && method == "GET") {
            path+= "?";
            Object.keys(args).forEach((key)=> {
                path+= key + "=" + encodeURI(args[key]) + "&";
            })
        }

        let headers = {
            'Content-Type': 'application/json'
        };
        if (customHeaders) {
            headers = {...headers, ...customHeaders};
        }
        if (authCredentials) {
            headers['Authorization'] = 'Bearer ' + authCredentials;
        }

        console.log(Blublu.getApiHost() + "/" + path);
        try {
            const response = await fetch(
                Blublu.getApiHost() + "/" + path,
                {
                    method: method || 'GET',
                    headers: headers,
                    timeout: 10000,
                    body: method != 'GET' ? JSON.stringify(args) : null

                }
            );

            const statusCode = response.status;
            console.log("STATUS: " + statusCode);
            console.log(path);
            if (statusCode == 401 || statusCode == 403) {
                let current = window.location.hash;
                if (["#/login", "#/reset-password"].indexOf(current) == -1) {
                    return window.location = "/#/login"
                } else {
                    return {success: false, login_required: true}
                }
                //return {success: false, authenticate: true};
            }
            if (statusCode == 404) {
                return {success: false, message: "Not found"};
            }

            const responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
            //alert(error)
            return false;
        }
    }

}
